import { graphql, useStaticQuery } from 'gatsby'

export function useGetTags() {
  const data = useStaticQuery<Queries.blogTagsQueryQuery>(graphql`
    query blogTagsQuery {
      allContentfulTag(sort: { totalPosts: DESC }, filter: { totalPosts: { gt: 3 } }) {
        edges {
          node {
            id
            tag
            slug
            totalPosts
          }
        }
      }
    }
  `)
  const tags = data?.allContentfulTag?.edges.map(({ node }) => ({
    id: node.id,
    tag: node.tag,
    slug: node.slug,
  }))

  return { tags }
}
