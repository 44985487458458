import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { styled } from '@linaria/react'
import Pluralize from 'react-pluralize'
import { VideoGrid } from './VideoGrid'
import { IconSocial } from '@/UI'
import { colors, media } from '@/lib/theme'
import { InsightsContainer } from '~/features/insights/common'

interface Props {
  pageTitle: string
}

export const Video = ({ pageTitle }: Props) => {
  const data = useStaticQuery<Queries.VideosQueryQuery>(graphql`
    query VideosQuery {
      selectedYoutubeVideos: youtubePlaylist(originalID: { eq: "PLrzGKxp1pvT_Q0UDDbfARhaFf-Cam7ZOS" }) {
        id
        videos {
          title
          videoId
          publishedAt(formatString: "DD MMMM YYYY", locale: "en-gb")
          localThumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 10)
            }
          }
        }
      }
    }
  `)

  const videos = (data?.selectedYoutubeVideos?.videos ?? []).filter(Boolean)

  if (!videos.length) return null

  return (
    <InsightsContainer
      title={pageTitle}
      subtitle={
        <p>
          Showing <Pluralize singular="selected video" count={videos.length} />. We have many more on the channel.
          <br />
          Visit and subscribe here:
        </p>
      }
      specialBlock={
        <LinkToOrigin target="_blank" href="https://youtube.com/samexperttv">
          <IconSocial
            type="Youtube"
            size={20}
            fillBg={colors.white}
            fillContent={colors.primary.primary}
            isNotInteractive
          />
          <YoutubeText>YouTube</YoutubeText>
        </LinkToOrigin>
      }
      content={<VideoGrid videos={videos} />}
    />
  )
}
const YoutubeText = styled.span`
  margin-left: 8px;
`

const LinkToOrigin = styled.a`
  display: flex;
  align-items: center;
  align-self: start;
  padding: 8px 16px;
  background-color: ${colors.primary.dark};
  transition: background-color 0.3s ease-in-out;
  color: #fff;
  cursor: pointer;
  user-select: none;
  justify-self: start;

  ${media.hover} {
    &:hover {
      background-color: ${colors.primary.darkest};
    }
  }
`
