import { BLOCKS } from '@contentful/rich-text-types'
import { CommonNode } from '@contentful/rich-text-react-renderer'
import { Post } from '~/features/insights/article/types'

interface Props extends Pick<Post, 'content'> {}

export const useGetTocNodes = ({ content }: Props) => {
  if (!content?.raw) return { nodes: [] as CommonNode[], hasTocNodes: false }

  const { content: items } = JSON.parse(content.raw)
  const headingTypes = [BLOCKS.HEADING_2]
  const nodes = items.filter(item => headingTypes.includes(item.nodeType)) as CommonNode[]
  const hasTocNodes = Boolean(nodes.length)
  return { nodes, hasTocNodes }
}
