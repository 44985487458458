import React from 'react'
import { Link } from 'gatsby'
import { styled } from '@linaria/react'
import { colors, fontLeagueSpartan } from '@/lib/theme'
import { Arrow, Wrapper } from '@/UI'
import { pxToRem } from '@/utils/unitsInRem'

interface Props {
  text: string
  parentNodePath?: string
  isH1?: boolean
}

export const BREADCRUMBS_HEIGHT = 48

export const Breadcrumbs = ({ parentNodePath, text, isH1 }: Props) => {
  const TextContent = <Text as={isH1 ? 'h1' : 'span'}>{text}</Text>

  return (
    <BreadcrumbsView>
      <Container>
        {parentNodePath ? (
          <StyledLink to={parentNodePath}>
            <ArrowStyled />
            {TextContent}
          </StyledLink>
        ) : (
          TextContent
        )}
      </Container>
    </BreadcrumbsView>
  )
}

const BreadcrumbsView = styled.div`
  background-color: ${colors.background.grayLightest};
  height: ${BREADCRUMBS_HEIGHT}px;
  width: 100%;
  display: flex;
  align-items: center;
`

const Container = styled(Wrapper)`
  display: flex;
  align-items: center;
  color: ${colors.secondary.secondary};
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
`

const ArrowStyled = styled(Arrow)`
  margin-right: 8px;
`

const Text = styled.span`
  font-family: ${fontLeagueSpartan};
  font-size: ${pxToRem(16)};
  font-weight: 700;
  line-height: 1.62;
  text-transform: uppercase;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 4px;
`
