import { styled } from '@linaria/react'
import { pxToRem } from '@/utils/unitsInRem'

const HighlightGrid = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  font-style: normal;
  font-weight: 300;
  font-size: ${pxToRem(24)};
  line-height: 133%;
  color: #9e958f;
  gap: 8px;
  align-items: center;

  span {
    font-weight: 400;
    font-size: ${pxToRem(80)};
    line-height: 80px;
    padding-bottom: 8px;
  }
`

export default HighlightGrid
