import { styled } from '@linaria/react'
import { media } from '@/lib/theme'

interface Props {
  $handlesTabletMq?: boolean
  $columnsDesktop?: number
  $columnsTablet?: number
}

export const AnnouncesGrid = styled.div<Props>`
  display: grid;
  grid-gap: 1rem;
  align-self: center;

  ${media.tablet} {
    grid-template-columns: ${({ $handlesTabletMq, $columnsTablet = 2 }) =>
      $handlesTabletMq ? `repeat(${$columnsTablet}, 1fr)` : 'unset'};
  }

  ${media.desktopSmall} {
    grid-gap: 1.5rem;
    align-self: unset;
    grid-template-columns: ${({ $columnsDesktop = 3 }) => `repeat(${$columnsDesktop}, 1fr)`}
  }
`
