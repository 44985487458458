import React from 'react'
import { styled } from '@linaria/react'
import { media } from '@/lib/theme'
import { BoxShadowStyle } from '@/UI'
import { pxToRem } from '@/utils/unitsInRem'
import { useDispatchActiveTagState } from '@/hooks'
import { Link } from 'gatsby'

interface Props {
  tagValue: string
  slug: string
}

export function Tag({ tagValue, slug }: Props) {
  const { activeTag, getActiveTagHref } = useDispatchActiveTagState()
  const isActive = activeTag === slug

  const activeTagHref = getActiveTagHref(slug, isActive)

  return (
    <TagView isActive={isActive} to={activeTagHref}>
      {tagValue}
    </TagView>
  )
}

const TagView = styled(Link)<{ isActive: boolean }>`
  ${BoxShadowStyle};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 38px;
  border-radius: 6px;
  padding: 0 16px;
  font-weight: 400;
  font-size: ${pxToRem(14)};
  color: rgba(39, 36, 36, 0.6);
  cursor: pointer;
  user-select: none;
  background: ${({ isActive }) => (isActive ? '#fff' : 'rgba(39, 36, 36, 0.1)')};
  transition-property: box-shadow, background;

  &:last-child {
    margin-right: 24px;

    ${media.desktopSmall} {
      margin-right: 0;
    }
  }

  &:first-child {
    margin-left: 24px;

    ${media.desktopSmall} {
      margin-left: 0;
    }
  }

  &:not(:last-child) {
    margin-right: 8px;
  }

  ${media.desktopSmall} {
    margin-bottom: 8px;
  }
`
