import React from 'react'
import { styled } from '@linaria/react'
import { Link } from 'gatsby'
import { Post } from '../types'
import { desktopSmallMedia, fontLeagueSpartan } from '@/lib/theme'
import { textLinkStyles, TitleMain } from '@/UI'
import { pxToRem } from '@/utils/unitsInRem'
import { useDispatchActiveTagState } from '@/hooks'

interface Props extends Pick<Post, 'tags' | 'title'> {}

export const TitleBlock = ({ tags, title }: Props) => {
  const { getActiveTagHref } = useDispatchActiveTagState()
  const tag = Array.isArray(tags) && tags.length > 0 ? tags[0] : null

  return (
    <Root>
      {tag && <Tag to={getActiveTagHref(tag.slug)}>{tag.tag}</Tag>}
      <TitleMain $variant="XXL">{title}</TitleMain>
    </Root>
  )
}

const Root = styled.section`
  font-family: ${fontLeagueSpartan};
  display: grid;
  grid-area: title;
  grid-row-gap: 24px;
  margin-top: 32px;
  margin-bottom: var(--content-blocks-gap);

  ${desktopSmallMedia} {
    margin: 64px 0 56px 0;
  }
`

const Tag = styled(Link)`
  ${textLinkStyles};
  font-size: ${pxToRem(18)};
  line-height: 22px;
  letter-spacing: 0.004em;
`
