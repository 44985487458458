import React from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia, fontLeagueSpartan, fontLibreBaskerville, mobileModernMedia } from '@/lib/theme'

import { Factoid } from '../types'
import { pxToRem } from '@/utils/unitsInRem'

const pieceColors: { fill: string; text: string }[] = [
  { fill: colors.white, text: colors.secondary.secondary },
  { fill: colors.tertiary.lightest, text: colors.secondary.secondary },
  { fill: colors.tertiary.darkest, text: colors.textStyles.inverse },
  { fill: colors.secondary.secondary, text: colors.textStyles.inverse },
]

interface Props extends Factoid {
  index: number
}

export const Piece = ({ text, figure, index }: Props) => {
  const divider = pieceColors.length
  const currentIndex = index % divider

  return (
    <Root $currentIndex={currentIndex}>
      <Figure>{figure}</Figure>
      <Text>{text}</Text>
    </Root>
  )
}

const Root = styled.div<{ $currentIndex: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: ${pxToRem(32)};
  position: relative;
  background-color: ${({ $currentIndex }) => pieceColors[$currentIndex].fill};
  color: ${({ $currentIndex }) => pieceColors[$currentIndex].text};
  margin: -${pxToRem(12)};
  width: ${pxToRem(155)};
  height: ${pxToRem(155)};

  ${mobileModernMedia} {
    width: ${pxToRem(176)};
    height: ${pxToRem(176)};
  }

  ${desktopSmallMedia} {
    margin: -16px;
    width: ${pxToRem(200)};
    height:${pxToRem(200)};
  }
`
const Figure = styled.span`
  font-family: ${fontLeagueSpartan};
  text-align: center;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.01em;
  font-size: ${pxToRem(40)};
  max-width: ${pxToRem(120)};

  ${desktopSmallMedia} {
    max-width: ${pxToRem(132)};
    font-size: ${pxToRem(48)};
  }
`

const Text = styled.span`
  text-align: center;
  font-family: ${fontLibreBaskerville};
  font-style: italic;
  font-weight: 400;
  line-height: 1.22;
  letter-spacing: -0.01em;
  font-size: ${pxToRem(16)};
  max-width: ${pxToRem(120)};

  ${desktopSmallMedia} {
    font-size: ${pxToRem(18)};
    max-width: ${pxToRem(132)};
  }
`
