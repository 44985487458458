import React, { MouseEvent, TouchEvent } from 'react'
import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import { BLOCKS, Document } from '@contentful/rich-text-types'
import { MacScrollbar } from 'mac-scrollbar'
import { Link } from 'gatsby'
import { useSnapshot } from 'valtio'
import { documentToReactComponents, CommonNode } from '@contentful/rich-text-react-renderer'
import { slugify } from '@/utils/slugify'
import { colors, desktopSmallMedia, fontLeagueSpartan, hoverMedia } from '@/lib/theme'
import { Post } from '../../types'
import { activeBlockIdState, needToScrollState } from '@/hooks'
import { Styleable } from '@/lib/interfaces'
import { tocVisibleState } from './state'
import { pxToRem } from '@/utils/unitsInRem'
import { useGetTocNodes } from '~/features/insights/article/components'

interface Props extends Pick<Post, 'content'>, Styleable {}

export const TableOfContents = ({ content, className }: Props) => {
  const { nodes, hasTocNodes } = useGetTocNodes({ content })

  if (!hasTocNodes) return null

  return (
    <Outer className={className}>
      <MacScrollbar className={contentStyle}>
        <Inner>{documentToReactComponents(document(nodes) as unknown as Document, tocOptions)}</Inner>
      </MacScrollbar>
    </Outer>
  )
}

const tocOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => {
      const { activeBlockId } = useSnapshot(activeBlockIdState)
      const slug = slugify(children)
      const link = `#${slug}`

      const linkClickHandler = (e: MouseEvent | TouchEvent) => {
        if (typeof window === 'undefined') return
        e.preventDefault()
        e.stopPropagation()
        activeBlockIdState.activeBlockId = slug
        needToScrollState.needToScroll = true
        const currentPath = window.location.pathname
        const newUrl = `${currentPath}${link}`
        window.history.replaceState(null, '', newUrl)
        tocVisibleState.isVisible = false
      }

      return (
        <Item $isActive={activeBlockId === slug}>
          <StyledAnchorLink to={link} onClick={linkClickHandler}>
            {children}
          </StyledAnchorLink>
        </Item>
      )
    },
  },
}

const document = items => {
  return {
    nodeType: 'document',
    content: items as CommonNode,
  }
}

const contentStyle = css`
  width: 100%;
`

const Outer = styled.nav`
  display: flex;
  max-height: 100%;
`

const Inner = styled.ul`
  display: flex;
  flex-direction: column;
`

const Item = styled.li<{ $isActive: boolean }>`
  display: flex;
  color: ${colors.tertiary.darkest};
  font-family: ${fontLeagueSpartan};
  font-weight: 400;
  line-height: 100%;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  transition-property: color, border-left-color;
  border-left: 2px solid ${colors.background.grayLightest};
  pointer-events: ${({ $isActive }) => ($isActive ? 'none' : 'all')};
  color: ${({ $isActive }) => ($isActive ? colors.primary.primary : colors.tertiary.darkest)};
  border-left: 2px solid ${({ $isActive }) => ($isActive ? colors.primary.primary : colors.background.grayLightest)};
  font-size: ${pxToRem(17)};

  ${hoverMedia} {
    &:hover {
      color: ${colors.secondary.darkest};
      border-left-color: currentColor;
    }
  }

  ${desktopSmallMedia} {
    font-size: ${pxToRem(15)};
  }
`

const StyledAnchorLink = styled(Link)`
  padding: 12px 0 12px 16px;
`
