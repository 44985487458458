import React from 'react'
import { styled } from '@linaria/react'
import { Hosts } from '@/blocks/Hosts'
import { Post } from '../types'
import { colors, desktopLargeMedia, desktopSmallMedia, fontLeagueSpartan } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'

interface Props extends Pick<Post, 'authors' | 'datetime'> {}

export const Info = ({ authors, datetime }: Props) => {
  return (
    <Root>
      <ArticleDate>{datetime}</ArticleDate>
      <HostsStyled hosts={authors} />
    </Root>
  )
}

const Root = styled.aside`
  display: grid;
  grid-area: info;
  grid-row-gap: 24px;
  align-self: start;
  padding-bottom: 24px;

  ${desktopLargeMedia} {
    padding: 24px 0;
  }
`

const ArticleDate = styled.time`
  color: ${colors.textStyles.regularTransparent};
  font-family: ${fontLeagueSpartan};
  font-size: ${pxToRem(18)};
  font-weight: 400;
  line-height: 110%;
  text-transform: uppercase;
  opacity: 0.5;
`

const HostsStyled = styled(Hosts)`
  ${desktopSmallMedia} {
    grid-auto-flow: column;
  }
  ${desktopLargeMedia} {
    grid-auto-flow: row;
  }
`
