import React from 'react'
import { styled } from '@linaria/react'
import { media } from '@/lib/theme'
import { Tag } from './Tag'

interface Props {
  tags: {
    id: string
    tag: string
    slug: string
  }[]
}

export const Tags = ({ tags }: Props) => {
  const hasTags = Array.isArray(tags)

  if (!hasTags) return null

  return (
    <Container $noMobilePadding>
      {tags.map(({ id, tag, slug }) => (
        <Tag key={id} tagValue={tag} slug={slug} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: visible;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: 100vw;

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.desktopSmall} {
    overflow-x: initial;
    overflow-y: initial;
    flex-wrap: wrap;
    margin-bottom: -8px;
  }
`
