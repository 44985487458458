import { styled } from '@linaria/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { colors, fontLeagueSpartan } from '@/lib/theme'

export const HighlightGatsbyImage = styled(GatsbyImage)`
  mix-blend-mode: lighten;
  [data-placeholder-image] {
    background-color: ${colors.black} !important;
  }

`

export const highlightTitleStyles = {
  fontFamily: fontLeagueSpartan,
  fontSize: 24,
  fontWeight: 700,
  lineHeight: 1.1,
  letterSpacing: '-0.01em',
}