import React, { useMemo, useState } from 'react'
import { useSnapshot, proxy } from 'valtio'
import { Player, VideoAnnounce, VideoType } from '@/UI'
import { AnnouncesGrid } from '@/blocks/announces'
import { InsightsBaseGrid, LoadMoreButton } from '~/features/insights/common'

const videosBlocksCountState = proxy<{ blocksCountOnScreen: number }>({ blocksCountOnScreen: 12 })

interface Props {
  videos: VideoType[]
}

export const VideoGrid = ({ videos }: Props) => {
  const numVideos = videos.length
  const { blocksCountOnScreen } = useSnapshot(videosBlocksCountState)
  const [blocksCountInc] = useState(blocksCountOnScreen)

  const { showMoreBtn, videosFinal } = useMemo(() => {
    const videosOnScreen = videos.slice(0, blocksCountOnScreen)
    return { videosFinal: videosOnScreen, showMoreBtn: numVideos > videosOnScreen.length }
  }, [blocksCountOnScreen, numVideos, videos])

  const loadMoreVideos = () => {
    videosBlocksCountState.blocksCountOnScreen += blocksCountInc
  }

  return (
    <InsightsBaseGrid>
      <AnnouncesGrid $handlesTabletMq>
        {videosFinal.map(video => (
          <VideoAnnounce key={video.videoId} video={video} />
        ))}
      </AnnouncesGrid>
      {showMoreBtn && <LoadMoreButton onClick={loadMoreVideos} size="medium" />}
      <Player />
    </InsightsBaseGrid>
  )
}
