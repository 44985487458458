import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { AnnouncesGrid, AnnouncesSection } from '@/blocks/announces'
import { ArticleAnnounce, ArticleAnnounceData } from '@/blocks/article-announce'

export const ArticlesAnnouncements = () => {
  const data = useStaticQuery<Queries.homepageArticlesAnnounceQuery>(graphql`
    query homepageArticlesAnnounce {
      allContentfulPost(sort: { datetime: DESC }, limit: 3) {
        edges {
          node {
            id
            slug
            title
            datetime(formatString: "DD MMMM YYYY", locale: "en-gb")
            featured
            excerpt {
              excerpt
              childMarkdownRemark {
                html
              }
            }
            image {
              fluid: gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
            }
            tags {
              tag
            }
            authors {
              name
              slug
              id
              miniavatar: avatar {
                gatsbyImageData(width: 30, layout: FIXED, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  `)
  const articles = data.allContentfulPost.edges

  if (!articles?.length) return null

  return (
    <AnnouncesSection title="Featured articles:" linkUrl="/blog" linkText="More articles" isOnHome>
      <AnnouncesGrid>
        {articles.map(({ node }) => (
          <ArticleAnnounce key={node.id} announce={node as unknown as ArticleAnnounceData} />
        ))}
      </AnnouncesGrid>
    </AnnouncesSection>
  )
}
