import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Layout, SEO } from '@/blocks/layout'
import { AllEpisodes } from '~/features/podcast'

export { globals } from '@/lib/global-styles'

const PodcastPage = () => {
  return (
    <Layout breadcrumbsText="podcast" hasNoVerticalMargins breadcrumbsIsH1>
      <AllEpisodes />
    </Layout>
  )
}

export default PodcastPage

export const Head = ({ data }: PageProps<Queries.podcastRSSQuery>) => {
  const { title, description, image } = data.allContentfulPodcastEpisode.nodes[0].podcast

  return <SEO title={title} description={description} image={image.gatsbyImageData.images.fallback.src} />
}

export const pageQuery = graphql`
  query podcastRSS {
    allContentfulPodcastEpisode(sort: { pubDate: DESC }) {
      nodes {
        podcast {
          title
          description
          image {
            gatsbyImageData
            title
          }
        }
      }
    }
  }
`
