import React from 'react'
import { styled } from '@linaria/react'
import { desktopSmallMedia } from '@/lib/theme'
import { useGetMenuItems } from './useGetMenuItems'
import { MenuItem } from './components'
import { pxToRem } from '@/utils/unitsInRem'

export const NavDesktop = () => {
  const menuItems = useGetMenuItems()

  return (
    <Nav>
      <MenuItems>
        {menuItems.map(item => (
          <MenuItem key={item.title} item={item} />
        ))}
      </MenuItems>
    </Nav>
  )
}

const Nav = styled.nav`
  display: none;
  height: 100%;

  ${desktopSmallMedia} {
    display: flex;
    align-items: center;
  }
`

const MenuItems = styled.ul`
  display: grid;
  height: 100%;
  grid-template-columns: repeat(4, max-content);
  grid-column-gap: 24px;
  font-size: ${pxToRem(16)};
  font-weight: 400;
  line-height: 22px;
  margin-right: 48px;
`
