import React from 'react'
import { Link } from 'gatsby'
import { styled } from '@linaria/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { colors, fontLeagueSpartan, media } from '@/lib/theme'
import { ArticleAuthors, AspectRatioContainer, BoxShadowStyle, makeH3Styles, makeTextBaseStyles } from '@/UI'
import type { ArticleAnnounceData } from './types'
import { usePrepareArticleAnnounceData } from '@/blocks/article-announce/usePrepareArticleAnnounceData'
import { Styleable } from '@/lib/interfaces'
import { pxToRem } from '@/utils/unitsInRem'

interface Props extends Styleable {
  announce: ArticleAnnounceData
}

export const ArticleAnnounce = ({ announce, className }: Props) => {
  const { postImage, tagsFinal, textFinal, titleFinal, dateTimeFinal, authorsFinal, slug } =
    usePrepareArticleAnnounceData(announce)
  return (
    <StyledLink to={`/${slug}/`} className={className}>
      <ArticleAnnounceView>
        <ImageBlock verticalRatio="60%">
          <GatsbyImage alt={titleFinal} image={postImage} />
        </ImageBlock>
        <Content>
          <ArticleAnnounceTags>{tagsFinal}</ArticleAnnounceTags>
          <ArticleAnnounceTitle>{titleFinal}</ArticleAnnounceTitle>
          <ArticleAnnounceText dangerouslySetInnerHTML={{ __html: textFinal }} />
          <BottomBlock>
            {dateTimeFinal && <ArticleAnnounceDateTime>{dateTimeFinal}</ArticleAnnounceDateTime>}
            <ArticleAuthors authors={authorsFinal} />
          </BottomBlock>
        </Content>
      </ArticleAnnounceView>
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  display: flex;
  justify-self: center;

  ${media.tablet} {
    justify-self: unset;
  }
`

const ArticleAnnounceView = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  padding: var(--layout-indent);
  border-radius: 16px;
  max-width: 480px;
  height: 100%;
  align-self: center;
  transition: box-shadow 0.2s ease-in-out;

  ${media.desktopSmall} {
    max-width: unset;
    align-self: unset;
    flex-direction: column;
  }
  ${BoxShadowStyle};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${media.desktopSmall} {
    align-self: unset;
  }
`

const ImageBlock = styled(AspectRatioContainer)`
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: var(--layout-indent);

  ${media.desktopSmall} {
    margin-right: 0;
    width: 100%;
  }
`

const BottomBlock = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
`

export const ArticleAnnounceTags = styled.div`
  color: ${colors.tertiary.tertiary};
  font-family: ${fontLeagueSpartan};
  font-size: ${pxToRem(18)};
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 1rem;
`

export const ArticleAnnounceTitle = styled.h3`
  ${makeH3Styles({})};
  margin-bottom: 0.5em;
`

export const ArticleAnnounceText = styled.div`
  ${makeTextBaseStyles({})};
  margin-bottom: 1.5em;
`

export const ArticleAnnounceDateTime = styled.span<{ $isInverse?: boolean }>`
  font-family: ${fontLeagueSpartan};
  font-weight: 400;
  font-size: ${pxToRem(18)};
  color: ${colors.textStyles.regularTransparent};
  opacity: 0.5;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 1rem;
  margin-right: 1rem;
  padding-top: 0.25rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: ${pxToRem(1)};
    height: 100%;
    background-color: ${colors.tertiary.tertiary};
  }
`
