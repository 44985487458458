import React from 'react'
import { styled } from '@linaria/react'
import { Link } from 'gatsby'
import { makeH2Styles, TextLink } from '@/UI'
import { Post } from '../types'
import { fontLeagueSpartan } from '@/lib/theme'
import { doNotPrintThisBlock } from '@/lib/sharedStyles'
import { pxToRem } from '@/utils/unitsInRem'
import { useDispatchActiveTagState } from '@/hooks'

interface Props {
  tags: Post['tags']
  masterPostId: string
}

const POSTS_TO_SHOW = 5

export const RelatedLinks = ({ tags, masterPostId }: Props) => {
  const { getActiveTagHref } = useDispatchActiveTagState()

  const tag = Array.isArray(tags) && tags.length > 0 ? tags[0] : null
  const posts = tag?.post?.length ? tag.post : null

  const postsFinal = posts && posts.slice(0, POSTS_TO_SHOW).filter(({ id }) => id !== masterPostId)
  const hasShowMore = posts && posts.length > POSTS_TO_SHOW

  if (!postsFinal?.length) return null

  return (
    <Root>
      <Title>Read next</Title>
      <Section>
        {postsFinal.map(({ id, slug, title }) => (
          <LinkStyled key={id} to={`/${slug}/`}>
            {title}
          </LinkStyled>
        ))}
      </Section>
      {hasShowMore && (
        <Link to={getActiveTagHref(tag.slug)}>
          <TextLinkStyled $size="medium">More articles</TextLinkStyled>
        </Link>
      )}
    </Root>
  )
}

const Root = styled.section`
  grid-area: related;
  display: grid;
  grid-row-gap: 32px;
  ${doNotPrintThisBlock};
  margin-top: var(--content-blocks-gap);
`

const Section = styled.nav`
  display: grid;
  grid-row-gap: 24px;
`
const Title = styled.h2`
  ${makeH2Styles({})};
`

const LinkStyled = styled(Link)`
  font-family: ${fontLeagueSpartan};
  font-size: ${pxToRem(20)};
  font-weight: 400;
  line-height: 110%;
  text-decoration: underline;
`
const TextLinkStyled = styled(TextLink)`
  justify-self: start;
`
