import React from 'react'
import { Posts, Tags } from './components'
import { InsightsContainer } from '../common'
import { useDispatchActiveTagState } from '@/hooks'
import { useGetTags } from '~/features/insights/blog/useGetTags'

export const Blog = () => {
  const { activeTag } = useDispatchActiveTagState()
  const { tags } = useGetTags()
  const titleTag = tags.find(({ slug }) => slug === activeTag)

  return (
    <InsightsContainer
      title={titleTag?.tag ?? 'All articles and guides'}
      specialBlock={<Tags tags={tags} />}
      content={<Posts />}
      noMobilePaddingSpecial
    />
  )
}
