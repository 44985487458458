export function useDispatchActiveTagState() {
  const getActiveTagHref = (tag: string, isActiveTag?: boolean) => (!isActiveTag ? `/blog/?activeTag=${tag}` : '/blog/')

  if (typeof window === 'undefined' || !window.location.search) return { activeTag: null, getActiveTagHref }

  const searchParams = new URLSearchParams(window.location.search)
  const activeTag = searchParams.get('activeTag')

  return { activeTag, getActiveTagHref }
}
