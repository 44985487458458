import React from 'react'
import { styled } from '@linaria/react'
import { Post } from '../types'
import { colors, fontLeagueSpartan } from '@/lib/theme'
import { AuthorCard } from '@/blocks/AuthorCard'
import { pxToRem } from '@/utils/unitsInRem'

interface Props {
  authors: Post['authors']
}

export const Authors = ({ authors }: Props) => {
  if (!Array.isArray(authors) || !authors.length) return null

  const title = authors.length > 1 ? 'Authors' : 'Author'

  return (
    <Root>
      <Title>{title}</Title>
      {authors.map(author => (
        <AuthorCard author={author} key={author.id} />
      ))}
    </Root>
  )
}

const Root = styled.aside`
  display: grid;
  grid-row-gap: 24px;
  grid-area: author-ext;
  padding-top: var(--content-blocks-gap);
  position: relative;
  margin-top: var(--content-blocks-gap);

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0.5;
    border-top: 1px dotted ${colors.tertiary.tertiary};
  }
`

const Title = styled.div`
  font-family: ${fontLeagueSpartan};
  font-size: ${pxToRem(36)};
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0.01em;
`
