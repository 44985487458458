import React from 'react'
import { styled } from '@linaria/react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { colors, fontLeagueSpartan } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'

export type AuthorType = {
  name: string
  img: IGatsbyImageData
  slug?: string
}

interface Props {
  className?: string
  isInverse?: boolean
  authors: AuthorType[]
}

export const ArticleAuthors = ({ authors, className, isInverse }: Props) => {
  if (!Array.isArray(authors) || !authors.length) return null

  const isOnlyOneAuthor = authors.length === 1

  return (
    <Container className={className}>
      {authors.map(({ img, name }, idx) => (
        <ImageContainer datatype="author_img" key={name} $moveCoefficient={idx}>
          <GatsbyImage image={img} alt={name} />
        </ImageContainer>
      ))}
      {isOnlyOneAuthor && (
        <Name $isInverse={isInverse} datatype="author_name">
          {authors[0].name}
        </Name>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const ImageContainer = styled.div<{ $moveCoefficient: number }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  border: 1px solid #fff;

  & img {
    filter: grayscale(100%);
  }

  &:not(:first-child) {
    transform: translateX(calc(-6px * ${({ $moveCoefficient }) => `${$moveCoefficient}`}));
  }
`

const Name = styled.span<{ $isInverse: boolean }>`
  padding-top: 3px;
  font-family: ${fontLeagueSpartan};
  font-weight: 400;
  font-size: ${pxToRem(18)};
  line-height: 0.94;
  letter-spacing: 0.004em;
  color: ${({ $isInverse }) => ($isInverse ? colors.tertiary.tertiary : colors.secondary.darkest)};
  margin-left: 8px;
`
