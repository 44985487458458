import { styled } from '@linaria/react'
import { pxToRem } from '@/utils/unitsInRem'

const SubSteps = styled.ul`
  padding-left: 16px;
  list-style: disc;
  font-weight: 500;
  font-size: ${pxToRem(12)};
  line-height: 160%;
  letter-spacing: 0.035em;
  text-transform: uppercase;
`

export default SubSteps
