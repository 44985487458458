import { media, MqType } from '@/lib/theme'

export function pxToRem(...values: [number, number?, number?, number?]): string {
  return values.map(value => `${value / 16}rem`).join(' ')
}

export interface ResponsiveFontSizesProps extends Partial<Record<MqType, number>> {
  base?: number
}

export function responsiveFontSizes({
  base,
  mobileModern,
  tablet,
  desktopSmall,
  desktopMedium,
  desktopLarge,
}: ResponsiveFontSizesProps) {
  let fontSizes = {}

  if (base) fontSizes = { fontSize: pxToRem(base) }
  if (mobileModern) fontSizes[media.mobileModern] = { fontSize: pxToRem(mobileModern) }
  if (tablet) fontSizes[media.tablet] = { fontSize: pxToRem(tablet) }
  if (desktopSmall) fontSizes[media.desktopSmall] = { fontSize: pxToRem(desktopSmall) }
  if (desktopMedium) fontSizes[media.desktopMedium] = { fontSize: pxToRem(desktopMedium) }
  if (desktopLarge) fontSizes[media.desktopLarge] = { fontSize: pxToRem(desktopLarge) }

  return fontSizes
}
