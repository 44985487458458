import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSnapshot } from 'valtio'
import { styled } from '@linaria/react'
import type { Episode } from '../../../types'
import { playerState, startPlay, stopPlay } from './state'
import { DownloadBlock, PlayButton, ProgressBar, RateBlock, SkipBlock, Share } from './components'
import { fontLeagueSpartan } from '@/lib/theme'

const kindsOfRates = [1, 1.25, 1.5, 2]

export interface PlayerProps extends Pick<Episode, 'file' | 'id' | 'bytes' | 'title'> {
  isOnMain?: boolean
  className?: string
  isOnSingle?: boolean
  cdnFolder: string
}

export const BlockPlayer = ({
  file,
  id,
  bytes,
  className,
  title,
  cdnFolder,
  isOnMain = false,
  isOnSingle = false,
}: PlayerProps) => {
  const { currentPlayedId } = useSnapshot(playerState)
  const playerRef = useRef<HTMLAudioElement>(null)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [rateCounter, setRateCounter] = useState(0)

  const fileWithPath = `${cdnFolder}${file}`

  const idFinal = isOnSingle ? `single_${id}` : id

  const isPlaying = currentPlayedId === idFinal

  const setRateHandler = useCallback(() => {
    setRateCounter(counter => counter + 1)
  }, [])

  const currentRate = useMemo(() => kindsOfRates[rateCounter % kindsOfRates.length], [rateCounter])

  useEffect(() => {
    if (!playerRef.current) return
    playerRef.current.playbackRate = currentRate
  }, [currentRate])

  const handleTimeUpdate = useCallback(() => {
    if (playerRef.current) {
      setCurrentTime(playerRef.current.currentTime)
    }
  }, [])

  const handleLoadedData = useCallback(() => {
    if (playerRef.current) {
      setDuration(playerRef.current.duration)
    }
  }, [])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!playerRef.current) return

    playerRef.current.addEventListener('timeupdate', handleTimeUpdate)
    playerRef.current.addEventListener('loadeddata', handleLoadedData)

    // eslint-disable-next-line consistent-return
    return () => {
      if (playerRef.current) {
        playerRef.current.removeEventListener('timeupdate', handleTimeUpdate)
        playerRef.current.removeEventListener('loadeddata', handleLoadedData)
      }
    }
  }, [handleLoadedData, handleTimeUpdate])

  const handleLoad = useCallback(() => {
    setTimeout(() => {
      playerRef?.current?.play()
    }, 0)
  }, [])

  const handleUnload = useCallback(() => {
    playerRef?.current?.pause()
  }, [])

  useEffect(() => {
    if (!playerRef.current) return

    async function togglePlayer() {
      if (currentPlayedId === idFinal) {
        handleLoad()
      } else {
        handleUnload()
      }
    }

    togglePlayer()
  }, [currentPlayedId, handleLoad, handleUnload, idFinal])

  const togglePlayerId = useCallback(() => {
    if (currentPlayedId === idFinal) {
      stopPlay()
    } else {
      startPlay(idFinal)
    }
  }, [currentPlayedId, idFinal])

  const jumpAhead = useCallback(() => {
    if (!playerRef.current) return
    const time = playerRef.current.currentTime
    playerRef.current.currentTime = time + 30
  }, [])

  const jumpBehind = useCallback(() => {
    if (!playerRef.current) return
    const time = playerRef.current.currentTime
    playerRef.current.currentTime = time - 15
  }, [])

  const preventEventsPropagationHandler = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
  }, [])

  return (
    <Container onClick={preventEventsPropagationHandler} className={className}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        id={idFinal}
        preload="true"
        ref={playerRef}
        onTimeUpdate={handleTimeUpdate}
        onLoadedData={handleLoadedData}
      >
        <source src={fileWithPath} type="audio/mp3" />
        Your browser does not support the <code>audio</code> element.
      </audio>
      <SkipBlock timeToSkip={15} isBehind onButtonClick={jumpBehind} />
      <PlayButton isPlaying={isPlaying} onBtnClick={togglePlayerId} isOnMain={isOnMain} />
      <SkipBlock timeToSkip={30} onButtonClick={jumpAhead} />
      <ProgressBar
        duration={duration}
        audioRef={playerRef}
        currentTime={currentTime}
        isOnMain={isOnMain}
        isPlaying={isPlaying}
      />
      <RateBlock
        onBlockClick={setRateHandler}
        rate={currentRate}
        isColored={currentRate !== kindsOfRates[0]}
        isOnMain={isOnMain}
      />
      <DownloadBlock src={fileWithPath} length={bytes} isOnMain={isOnMain} />
      <Share isOnMain={isOnMain} id={id} title={title} />
    </Container>
  )
}

const Container = styled.div`
  font-family: ${fontLeagueSpartan};
  cursor: default;
  display: flex;
  align-items: center;
  position: relative;
`
