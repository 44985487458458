import dayjs from 'dayjs'
import type { IGatsbyImageData } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import type { ArticleAnnounceData } from './types'
import type { RichTextContentType } from '@/blocks/rich-text-improoved'
import type { AuthorType } from '@/UI'

export function usePrepareArticleAnnounceData(data: ArticleAnnounceData) {
  const { title, authors, datetime, slug, tags, excerpt, image } = data

  const defaultImageQueryQuery = useStaticQuery<Queries.blogDefaultImageQueryQuery>(graphql`
    query blogDefaultImageQuery {
      defaultImage: contentfulDefaultImage(for: { eq: "Page" }) {
        image {
          fluid: gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
        }
      }
    }
  `)

  const { defaultImage } = defaultImageQueryQuery

  const tagsFinal = Array.isArray(tags) && tags[0].tag
  const titleFinal = title ?? slug
  const textFinal = excerpt?.childMarkdownRemark?.html as unknown as RichTextContentType
  const authorsFinal = authors?.map(
    ({ name, miniavatar }) => ({ name, img: miniavatar.gatsbyImageData } as unknown as AuthorType),
  )
  const dateTimeFinal = datetime ? dayjs(datetime).format('DD.MM.YYYY') : null

  const postImage = (image?.fluid ?? defaultImage.image.fluid) as unknown as IGatsbyImageData

  return { titleFinal, textFinal, authorsFinal, dateTimeFinal, postImage, tagsFinal, slug }
}
