import React from 'react'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import { Button, ButtonState } from '@/UI'
import { formDataState, sendingState } from '../formState'
import { useFormSubmitHandler } from '../useFormSubmitHandler'
import { desktopSmallMedia, fontLeagueSpartan } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'

type ButtonAppearance = { buttonState: ButtonState; text: string }

export const Submit = () => {
  const formSubmitHandler = useFormSubmitHandler()
  const { text, buttonState } = useGetButtonAppearance()

  return (
    <Container>
      <Button type="dark" state={buttonState} onClick={formSubmitHandler} desktopWidth="100%">
        {text}
      </Button>
      <Text>A senior expert will contact you soon.</Text>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-gap: 16px;
  z-index: 1;

  ${desktopSmallMedia} {
    grid-template-columns: 180px auto;
  }
`

const Text = styled.span`
  font-family: ${fontLeagueSpartan};
  color: var(--text-placeholder, #9e958f);
  font-size: ${pxToRem(18)};
  font-weight: 400;
  line-height: 110%; /* 17.6px */
  letter-spacing: -0.4px;
  flex-shrink: 1;
`

function useGetButtonAppearance(): ButtonAppearance {
  const { isValid } = useSnapshot(formDataState)
  const { currentState } = useSnapshot(sendingState)
  switch (true) {
    case currentState === 'initial' && !isValid:
      return {
        buttonState: 'isDisabled',
        text: 'Send',
      }
    case currentState === 'initial' && isValid:
      return {
        buttonState: null,
        text: 'Send',
      }
    case currentState === 'isPending':
      return {
        buttonState: 'isPending',
        text: 'Sending...',
      }
    case currentState === 'isFulfilled':
      return {
        buttonState: 'isPositive',
        text: 'Success',
      }
    case currentState === 'isError':
      return {
        buttonState: 'isNegative',
        text: 'Failed',
      }
    default:
      return {
        buttonState: null,
        text: '',
      }
  }
}
