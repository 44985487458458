import React, { ReactNode } from 'react'
import { Link } from 'gatsby'
import { styled } from '@linaria/react'
import { TextLink, Wrapper, Break, Divider, makeH2Styles } from '@/UI'
import { colors, desktopSmallMedia, media } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'

interface SectionProps {
  children: ReactNode
  title?: string
  linkUrl?: string
  linkText?: string
  hasDivider?: boolean
  noBackground?: boolean
  isOnHome?: boolean
}

export const AnnouncesSection = ({
  children,
  title,
  linkUrl,
  hasDivider,
  noBackground,
  isOnHome,
  linkText = 'Show more',
}: SectionProps) => {
  return (
    <AnnouncesSectionView $noBackground={noBackground}>
      {hasDivider ? (
        <Wrapper $noMobilePadding={false}>
          <Divider $customMargin={64} $customMarginMob={48} />
        </Wrapper>
      ) : (
        <Break $indent={64} $indentMobile={48} />
      )}
      {title && (
        <Wrapper $noMobilePadding={false}>
          <TitleStyled $isOnHome={isOnHome}>{title}</TitleStyled>
        </Wrapper>
      )}
      <Inner $noMobilePadding={false} $noUltraThinPadding>
        {children}
        {linkUrl && (
          <LinkWrapper>
            <Link to={linkUrl}>
              <TextLink $size={isOnHome ? 'medium' : 'big'}>{linkText}</TextLink>
            </Link>
          </LinkWrapper>
        )}
      </Inner>
    </AnnouncesSectionView>
  )
}

const TitleStyled = styled.h2<{ $isOnHome: boolean }>`
  ${makeH2Styles({})}
  font-weight: ${({ $isOnHome }) => ($isOnHome ? 400 : 700)};
  font-size: ${({ $isOnHome }) => ($isOnHome ? pxToRem(24) : pxToRem(36))};
  margin-bottom: 2rem;

  ${media.desktopSmall} {
    margin-bottom: 3rem;
    color: ${({ $isOnHome }) => ($isOnHome ? colors.secondary.lightest : colors.secondary.secondary)};
  }
`

const AnnouncesSectionView = styled.div<{ $noBackground: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ $noBackground }) => ($noBackground ? colors.white : colors.background.grayLightest)};
  padding: 0 0 3rem 0;

  ${desktopSmallMedia} {
    padding: 0 0 4rem 0;
  }
`

const Inner = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`
const LinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  ${desktopSmallMedia} {
    justify-content: flex-end;
    margin-top: 3rem;
  }
`
