import React, { ReactNode } from 'react'
import { styled } from '@linaria/react'
import { Extras, Header, Footer } from './partials'
import { WithChildren } from '@/lib/interfaces'
import {
  SubHeader,
  type SubheaderProps,
  BREADCRUMBS_HEIGHT,
  calculateSubheaderItemsVisibility,
  MediaShowSubheader,
} from '@/blocks/layout/partials/sub-header'
import { colors, media } from '@/lib/theme'

interface Props extends WithChildren, SubheaderProps {
  subheaderItemsHeight?: number
  subheaderItems?: ReactNode
  isOnGrayBg?: boolean
  hasNoVerticalMargins?: boolean
}

export const Layout = ({
  children,
  isOnGrayBg,
  subheaderItems,
  breadcrumbsText,
  breadcrumbsLink,
  breadcrumbsIsH1,
  hasNoVerticalMargins,
  bpToShowSubheaderItems,
  subheaderItemsHeight = 0,
}: Props) => {
  const hasBreadcrumbs = Boolean(breadcrumbsText)
  const hasSubheader = hasBreadcrumbs || subheaderItems
  const subheaderHeight = hasBreadcrumbs ? subheaderItemsHeight + BREADCRUMBS_HEIGHT : subheaderItemsHeight
  const subheaderHeightWithoutItems = hasBreadcrumbs ? BREADCRUMBS_HEIGHT : 0

  return (
    <Wrapper
      $subheaderHeight={subheaderHeight}
      $subheaderHeightWithoutItems={subheaderHeightWithoutItems}
      $bpToShowSubheaderItems={bpToShowSubheaderItems}
    >
      <Header withShadow={!hasSubheader} />
      {hasSubheader && (
        <SubHeader
          breadcrumbsText={breadcrumbsText}
          breadcrumbsLink={breadcrumbsLink}
          breadcrumbsIsH1={breadcrumbsIsH1}
          bpToShowSubheaderItems={bpToShowSubheaderItems}
        >
          {subheaderItems}
        </SubHeader>
      )}
      <Main $grayColored={isOnGrayBg} $isNoPaddings={hasNoVerticalMargins}>
        {children}
      </Main>
      <Footer />
      <Extras />
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  $subheaderHeight: number
  $subheaderHeightWithoutItems: number
  $bpToShowSubheaderItems: MediaShowSubheader[]
}>`
  --layout-indent: 1rem;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: var(--all-top-blocks-h);

  --all-top-blocks-h: calc(
    var(--header-h) +
      ${({ $subheaderHeight, $subheaderHeightWithoutItems, $bpToShowSubheaderItems }) =>
        calculateSubheaderItemsVisibility($bpToShowSubheaderItems, 'mobile')
          ? $subheaderHeight
          : $subheaderHeightWithoutItems}px
  );

  ${media.mobileModern} {
    --layout-indent: 1.5rem;
  }

  ${media.tablet} {
    --all-top-blocks-h: calc(
      var(--header-h) +
        ${({ $subheaderHeight, $subheaderHeightWithoutItems, $bpToShowSubheaderItems }) =>
          calculateSubheaderItemsVisibility($bpToShowSubheaderItems, 'tablet')
            ? $subheaderHeight
            : $subheaderHeightWithoutItems}px
    );
  }

  ${media.desktopSmall} {
    --all-top-blocks-h: calc(
      var(--header-h) +
        ${({ $subheaderHeight, $subheaderHeightWithoutItems, $bpToShowSubheaderItems }) =>
          calculateSubheaderItemsVisibility($bpToShowSubheaderItems, 'desktopSmall')
            ? $subheaderHeight
            : $subheaderHeightWithoutItems}px
    );
  }

  ${media.desktopLarge} {
    --all-top-blocks-h: calc(
      var(--header-h) +
        ${({ $subheaderHeight, $subheaderHeightWithoutItems, $bpToShowSubheaderItems }) =>
          calculateSubheaderItemsVisibility($bpToShowSubheaderItems, 'desktopLarge')
            ? $subheaderHeight
            : $subheaderHeightWithoutItems}px
    );
  }

  ${media.print} {
    --all-top-blocks-h: 0;
  }
`

const Main = styled.main<{ $grayColored: boolean; $isNoPaddings: boolean }>`
  width: 100%;
  min-height: 80vh;
  --content-blocks-gap: 48px;
  --layout-vertical-padding: 32px;
  background-color: ${({ $grayColored }) => ($grayColored ? colors.background.grayLightest : 'transparent')};
  padding-top: ${({ $isNoPaddings }) => ($isNoPaddings ? 0 : 'var(--layout-vertical-padding)')};
  padding-bottom: ${({ $isNoPaddings }) => ($isNoPaddings ? 0 : 'var(--layout-vertical-padding)')};

  ${media.mobileModern} {
    --layout-vertical-padding: 48px;
  }

  ${media.desktopSmall} {
    --content-blocks-gap: 60px;
    --layout-vertical-padding: 64px;
  }
`
