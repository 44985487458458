import React from 'react'
import { styled } from '@linaria/react'
import { Link } from 'gatsby'
import { useCloseSearchHandler } from '../../../../useCloseSearchHandler'
import { SearchResultsData } from '../types'
import { colors, desktopSmallMedia, hoverMedia } from '@/lib/theme'
import { Arrow } from '@/UI'
import { pxToRem } from '@/utils/unitsInRem'

export const CustomHit = ({ hit }: { hit: SearchResultsData }) => {
  const closeSearchHandler = useCloseSearchHandler()

  return (
    <LinkStyled to={hit.url} onClick={closeSearchHandler} tabIndex={0}>
      <span>{hit.title}</span>
      <ArrowStyled direction="right" />
    </LinkStyled>
  )
}

const ActiveStyle = {
  color: colors.primary.primary,
  borderTopColor: colors.primary.primary,
  borderRightColor: colors.primary.primary,
  borderLeftColor: colors.primary.primary,

  '&:last-child': {
    borderBottomColor: colors.primary.primary,
  },

  '& + a': {
    borderTopColor: colors.primary.primary,
  },

  '& svg': {
    fill: colors.primary.primary,
  },
}

const LinkStyled = styled(Link)`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  user-select: none;
  color: rgba(255, 255, 255, 0.8);
  transition-property: color, border-color, background-color;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  padding: 18px 0 14px 0;
  font-size: ${pxToRem(18)};
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-align: left;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  ${desktopSmallMedia} {
    padding: 18px 20px 14px 20px;
  }

  & svg {
    fill: transparent;
    transition: fill 0.2s ease-in-out;
  }

  ${hoverMedia} {
    &:hover {
      ${ActiveStyle};
    }
  }

  &:focus {
    ${ActiveStyle};
  }

  &:focus-visible {
    ${ActiveStyle};
  }

  &:focus-within {
    ${ActiveStyle};
  }

  &:active {
    ${ActiveStyle};
    color: ${colors.secondary.darkest};
    background-color: ${colors.primary.primary};

    & svg {
      fill: ${colors.secondary.darkest};
    }
  }
`

const ArrowStyled = styled(Arrow)`
  margin-left: 16px;
  transform: translateY(-2px) scaleX(-1);
`
