import React, { useCallback } from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia, hoverMedia, theme } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'

interface Props {
  isOnMain?: boolean
  src: string
  length: number
}

export const DownloadBlock = ({ src, length, isOnMain = false }: Props) => {
  const clickHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
      e.preventDefault()
      window.open(src, '_blank')
    },
    [src],
  )

  return (
    <Container isOnMain={isOnMain} onClick={clickHandler}>
      <svg fill="currentColor" height="22" viewBox="0 0 22 22" width="22">
        <path d="m11 16.5-6.875-6.875 1.925-1.99375 3.575 3.57495v-11.2062h2.75v11.2062l3.575-3.57495 1.925 1.99375zm-8.25 5.5c-.75625 0-1.40387-.2695-1.942873-.8085-.539-.539-.80804133-1.1862-.80712467-1.9415v-4.125h2.74999767v4.125h16.5v-4.125h2.75v4.125c0 .7562-.2695 1.4039-.8085 1.9429s-1.1862.808-1.9415.8071z" />
      </svg>
      <Text>{formatFileSize(length)}</Text>
    </Container>
  )
}

const Container = styled.div<{ isOnMain: boolean }>`
  display: none;

  ${desktopSmallMedia} {
    display: flex;
    position: absolute;
    color: ${colors.tertiary.tertiary};
    user-select: none;
    pointer-events: all;
    cursor: pointer;
    align-items: center;
    font-size: ${pxToRem(16)};
    font-style: normal;
    font-weight: 400;
    z-index: 1;
    line-height: 110%; /* 17.6px */
    text-transform: uppercase;
    left: ${({ isOnMain }) => (isOnMain ? 208 : 176)}px;
    bottom: ${({ isOnMain }) => (isOnMain ? 12 : 0)}px;

    ${hoverMedia} {
      transition: color 0.2s ease-in-out;

      &:hover {
        color: ${theme.hover};
      }
    }
  }
`

const Text = styled.span`
  margin-left: 8px;
  padding-top: 4px;
`

function formatFileSize(bytes: number) {
  if (bytes < 1024) {
    return `${bytes} B`
  }
  if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(2)} KB`
  }
  return `${(bytes / (1024 * 1024)).toFixed(2)} MB`
}
