import React from 'react'
import { styled } from '@linaria/react'
import { type RichTextContentType, RichTextImproved } from '@/blocks/rich-text-improoved'
import { makeH3Styles } from '@/UI'

interface Props {
  title: string
  text: RichTextContentType
}

export const Block = ({ title, text }: Props) => {
  return (
    <Root>
      <Title>{title}</Title>
      <RichTextImproved content={text} />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h3`
  ${makeH3Styles({})};
  margin-bottom: 0.5em;
`
