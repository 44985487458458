import React from 'react'
import { styled } from '@linaria/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import { VideoType } from './types'
import { AspectRatioContainer, selectVideo, BoxShadowStyle } from '@/UI'

interface VideoThumbnailProps {
  picture: VideoType['localThumbnail']
  alt: string
  className?: string
}

interface VideoThumbnailInteractiveProps extends VideoThumbnailProps {
  videoId: string
}

export const VideoThumbnail = ({ picture, alt, className }: VideoThumbnailProps) => {
  const thumbnail = getImage(picture)

  const { allFile } = useStaticQuery<Queries.Query>(
    graphql`
      query {
        allFile(filter: { extension: { eq: "png" }, name: { eq: "play" } }) {
          edges {
            node {
              name
              publicURL
            }
          }
        }
      }
    `,
  )
  const pseudoBtnUrl = allFile.edges[0]?.node.publicURL

  return (
    <AspectRatioContainer verticalRatio="56%" className={className}>
      <GatsbyImage alt={alt} image={thumbnail} />
      <PseudoButtonContainer>
        <PseudoBtn alt="Watch me" src={pseudoBtnUrl} />
      </PseudoButtonContainer>
    </AspectRatioContainer>
  )
}

export const VideoThumbnailInteractive = ({ videoId, ...thumbnailProps }: VideoThumbnailInteractiveProps) => (
  <div onClick={() => selectVideo(videoId)}>
    <VideoThumbnailWithShadow {...thumbnailProps} />
  </div>
)

const PseudoButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 1;
  pointer-events: none;
`

const PseudoBtn = styled.img`
  width: 100px;
  height: 100px;
`

const VideoThumbnailWithShadow = styled(VideoThumbnail)`
  ${BoxShadowStyle};
`
