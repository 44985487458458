import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { useLocation } from '@reach/router'
import { schemaOrgOrganizationShortObject } from '@/utils/schema.org'
import { SEO, Layout } from '@/blocks/layout'
import { Article, TocMobile, useGetTocNodes } from '~/features/insights'

const ArticleTemplate = ({ data, pageContext }: PageProps<Queries.ContentfulBlogPostBySlugQuery>) => {
  const { hasTocNodes } = useGetTocNodes({ content: data.contentfulPost.content })

  return (
    <Layout
      breadcrumbsLink="/blog"
      breadcrumbsText="articles and guides"
      subheaderItemsHeight={hasTocNodes ? 48 : 0}
      bpToShowSubheaderItems={['mobile', 'tablet']}
      subheaderItems={hasTocNodes && <TocMobile content={data.contentfulPost.content} />}
      hasNoVerticalMargins
    >
      <Article post={data.contentfulPost} slug={(pageContext as { slug: string }).slug} />
    </Layout>
  )
}

export default ArticleTemplate

export const Head = ({ data }: PageProps<Queries.ContentfulBlogPostBySlugQuery>) => {
  const { contentfulDefaultImage, contentfulPost, site } = data
  const { authors, image, title, createdAt, updatedAt, excerpt } = contentfulPost
  const location = useLocation()

  const heroImage = image || contentfulDefaultImage.image
  const seoImage = heroImage.fixed.images.fallback.src

  const richResultAuthors = authors.map(author => {
    return {
      '@type': 'Person',
      name: author.name,
      url: `https://samexpert.com/team/${author.slug}/`,
    }
  })

  const richResultObject = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    headline: title,
    image: seoImage,
    datePublished: createdAt,
    dateModified: updatedAt,
    author: richResultAuthors,
    publisher: schemaOrgOrganizationShortObject(site.siteMetadata),
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${site.siteMetadata.siteUrl}${location.pathname}`,
    },
  }

  return (
    <SEO title={title} description={excerpt.excerpt} image={seoImage} richresult={JSON.stringify(richResultObject)} />
  )
}

export const pageQuery = graphql`
  query ContentfulBlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
        email
      }
    }
    contentfulPost(slug: { eq: $slug }) {
      id
      title
      excerpt {
        excerpt
      }
      datetime(formatString: "DD MMMM YYYY", locale: "en-gb")
      createdAt
      updatedAt
      content {
        raw
        references {
          ... on ContentfulAsset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
            contentful_id
            title
          }
          ... on ContentfulYoutubeEmbed {
            contentful_id
            __typename
            videoId {
              videoId
              title
              description
              publishedAt
              duration
              likeCount
              viewCount
              commentCount
              thumbnail {
                url
              }
            }
            title
          }
        }
      }
      image {
        fluid: gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
        fixed: gatsbyImageData(width: 1200, height: 630, formats: [AUTO, WEBP], quality: 20)
      }
      tags {
        tag
        id
        slug
        totalPosts
        post {
          id
          timestamp: datetime
          datetime(formatString: "DD MMMM YYYY", locale: "en-gb")
          title
          slug
        }
        image {
          fluid: gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      authors {
        name
        slug
        id
        linkedIn
        twitter
        role
        bookACall
        bio {
          bio
          childMarkdownRemark {
            html
          }
        }
        avatar {
          gatsbyImageData(width: 392, layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP], quality: 50)
        }
        miniavatar: avatar {
          gatsbyImageData(width: 32, layout: FIXED, placeholder: BLURRED, formats: [AUTO, WEBP], quality: 50)
        }
      }
    }
    contentfulDefaultImage(for: { eq: "Article" }) {
      image {
        fluid: gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
        fixed: gatsbyImageData(width: 1200, height: 630, formats: [AUTO, WEBP], quality: 20)
      }
    }
  }
`
