import { styled } from '@linaria/react'
import { media } from '@/lib/theme'

export const InsightsBaseGrid = styled.div`
  display: grid;
  grid-row-gap: 48px;

  ${media.desktopSmall} {
    grid-row-gap: 56px;
  }
`
