import React, { ReactNode } from 'react'
import { styled } from '@linaria/react'
import { Typography, Wrapper } from '@/UI'
import { InsightsBaseGrid } from '~/features/insights/common/InsightsBaseGrid'

interface Props {
  title: string
  subtitle?: ReactNode
  specialBlock?: ReactNode
  content: ReactNode
  noMobilePaddingContent?: boolean
  noMobilePaddingSpecial?: boolean
}

export const InsightsContainer = ({
  title,
  subtitle,
  specialBlock,
  content,
  noMobilePaddingContent,
  noMobilePaddingSpecial,
}: Props) => {
  return (
    <InsightsBaseGrid $noMobilePadding={noMobilePaddingContent}>
      <TopBlock>
        <Wrapper>
          <Typography>
            <h1>{title}</h1>
            {subtitle}
          </Typography>
        </Wrapper>
        <WrapperStyled $noMobilePadding={noMobilePaddingSpecial}>{specialBlock}</WrapperStyled>
      </TopBlock>
      <Wrapper $noMobilePadding={noMobilePaddingContent}>{content}</Wrapper>
    </InsightsBaseGrid>
  )
}

const TopBlock = styled.div`
  display: grid;
  grid-row-gap: 32px;
`

const WrapperStyled = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`