import React from 'react'
import { AllLinksWrapper } from './AllLinksWrapper'
import { ExternalLinkTemplate } from './ExternalLinkTemplate'

export const Resources = () => {
  return (
    <AllLinksWrapper gridArea="resources">
      <h2>Resources</h2>
      <ExternalLinkTemplate href="https://www.linkedin.com/company/samexpert/events/" text="Events" />
      <ExternalLinkTemplate href="https://zcmp.eu/NsBb" text="Newsletter" />
    </AllLinksWrapper>
  )
}
