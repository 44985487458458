import { CSSProperties } from '@linaria/core'
import { media, boxShadow, transition } from '@/lib/theme'

export const BoxShadowStyle: CSSProperties = {
  transition,

  [media.hover]: {
    '&:hover': {
      boxShadow,
      cursor: 'pointer',
    },
  },
}
