import { styled } from '@linaria/react'
import {
  //tabletMedia,
  desktopSmallMedia, //desktopLargeMedia
} from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'

const StepTitle = styled.h3`
  padding-top: 4px;
  font-weight: 700;
  font-size: ${pxToRem(20)};
  line-height: 120%;
  ${desktopSmallMedia} {
    text-align: center;
  }
`

export default StepTitle
