import React from 'react'
import { styled } from '@linaria/react'
import { makeH3Styles, Typography } from '@/UI'
import { colors } from '@/lib/theme'
import { type RichTextContentType, RichTextImproved } from '@/blocks/rich-text-improoved'

interface Props {
  content: RichTextContentType
  summary: string | null | undefined
}

export const Content = ({ content, summary }: Props) => {
  return (
    <Root>
      {summary && (
        <Summary>
          <SummaryTitle>Summary</SummaryTitle>
          <Typography dangerouslySetInnerHTML={{ __html: summary }} />
        </Summary>
      )}
      <RichTextImproved content={content} parseReferences useToc />
    </Root>
  )
}

const Root = styled.article`
  grid-area: content;
`

const Summary = styled.section`
  display: grid;
  grid-row-gap: 8px;
  background-color: ${colors.primary.lightest};
  border-radius: 0 16px 16px 0;
  padding: 24px 24px 24px 32px;
  border-left: 8px solid ${colors.primary.primary};
  margin-bottom: var(--content-blocks-gap);
`

const SummaryTitle = styled.h3`
  ${makeH3Styles({})};
`
