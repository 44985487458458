import React from 'react'
import { styled } from '@linaria/react'
import { colors, media, theme } from '@/lib/theme'
import { VideoType } from './types'
import { selectVideo } from './selectedVideoState'
import { VideoThumbnail } from './VideoThumbnail'
import { makeH3Styles } from '@/UI'
import { pxToRem } from '@/utils/unitsInRem'

interface Props {
  video: VideoType
}

export const VideoAnnounce = ({ video }: Props) => {
  const { title, videoId, localThumbnail } = video

  return (
    <VideoAnnounceView onClick={() => selectVideo(videoId)}>
      <VideoThumbnailStyled picture={localThumbnail} alt={title} />
      <Title>{title}</Title>
    </VideoAnnounceView>
  )
}

const VideoAnnounceView = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background-color: ${colors.secondary.secondary};
  transition: box-shadow 0.2s ease-in-out;
  padding: var(--layout-indent);

  ${media.hover} {
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 0.5rem ${pxToRem(2)} ${theme.hover};
    }
  }
`

const VideoThumbnailStyled = styled(VideoThumbnail)`
  border-radius: 0.5rem;
  overflow: hidden;
`

const Title = styled.span`
  ${makeH3Styles({})};
  color: ${colors.white};
  margin-top: var(--layout-indent);
`
