import { styled } from '@linaria/react'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { makeH2Styles } from '@/UI/typography-new'

interface TitleProps {
  $isInverted?: boolean
  $gap?: number
  $gapMob?: number
  $isCenteredOnMob?: boolean
}

export const TitleH2 = styled.h2<TitleProps>`
  ${makeH2Styles({})};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${({ $gap, $gapMob }) => {
    if (typeof $gapMob === 'number') return $gapMob
    if (typeof $gap === 'number') return $gap
    return 24
  }}px;
  color: ${({ $isInverted }) => (!$isInverted ? colors.secondary.secondary : colors.white)};
  align-self: ${({ $isCenteredOnMob }) => ($isCenteredOnMob ? 'center' : 'start')};

  ${desktopSmallMedia} {
    align-self: start;
    margin-bottom: ${({ $gap }) => $gap ?? 24}px;
  }
`
