import React from 'react'
import { styled } from '@linaria/react'
import { TextLink, type TextLinkSize } from '@/UI'

interface Props {
  text?: string
  onClick: () => void
  size?: TextLinkSize
}

export const LoadMoreButton = ({ onClick, size = 'big', text = 'Load More' }: Props) => {
  return (
    <LoadMoreButtonView onClick={onClick} $size="big">
      <TextLink $size={size}>{text}</TextLink>
    </LoadMoreButtonView>
  )
}


const LoadMoreButtonView = styled.button`
  cursor: pointer;
`
