import React, { useMemo, useState } from 'react'
import { proxy, useSnapshot } from 'valtio'
import { graphql, useStaticQuery } from 'gatsby'
import { ArticleAnnounce, type ArticleAnnounceData } from '@/blocks/article-announce'

import { InsightsBaseGrid, LoadMoreButton } from '../../../common'
import { AnnouncesGrid } from '@/blocks/announces'
import { useDispatchActiveTagState } from '@/hooks'

const postsBlocksCountState = proxy<{ blocksCountOnScreen: number }>({ blocksCountOnScreen: 12 })

type PostData = { node: ArticleAnnounceData & { id: string; featured: boolean } }

export const Posts = () => {
  const data = useStaticQuery<Queries.blogPostsQueryQuery>(graphql`
    query blogPostsQuery {
      allContentfulPost(sort: { datetime: DESC }) {
        edges {
          node {
            id
            slug
            title
            datetime(formatString: "DD MMMM YYYY", locale: "en-gb")
            featured
            excerpt {
              excerpt
              childMarkdownRemark {
                html
              }
            }
            image {
              fluid: gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
            }
            tags {
              tag
              slug
            }
            authors {
              name
              slug
              id
              miniavatar: avatar {
                gatsbyImageData(width: 30, layout: FIXED, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  `)

  const posts = data?.allContentfulPost?.edges
  const hasPosts = Array.isArray(posts)

  const { activeTag } = useDispatchActiveTagState()
  const { blocksCountOnScreen } = useSnapshot(postsBlocksCountState)
  const [blocksCountInc] = useState(blocksCountOnScreen)

  const { filteredFeaturedPosts, filteredOrdinaryPosts, countOfFeaturedBlocks, countOfOrdinaryBlocks } = useMemo(() => {
    if (!Array.isArray(posts)) return { filteredPosts: [] }

    const filteredByTagPosts = (posts as PostData[]).filter(({ node }) => {
      if (activeTag === null) return true

      const { tags } = node
      const tagsArray = tags?.map(({ slug }) => slug) ?? []

      return tagsArray.includes(activeTag)
    })

    const filteredFeatured = filteredByTagPosts.filter(({ node }) => node.featured)
    const filteredOrdinary = filteredByTagPosts.filter(({ node }) => !node.featured)

    const featuredBlocks = filteredFeatured.length
    const ordinaryBlocks = filteredOrdinary.length

    return {
      filteredFeaturedPosts: filteredFeatured as PostData[],
      filteredOrdinaryPosts: filteredOrdinary as PostData[],
      countOfFeaturedBlocks: featuredBlocks,
      countOfOrdinaryBlocks: ordinaryBlocks,
    }
  }, [posts, activeTag])

  const { postsToShow, showLoadMoreBtn } = useMemo(() => {
    const featuredPostsOnScreen =
      countOfFeaturedBlocks < blocksCountOnScreen
        ? filteredFeaturedPosts
        : filteredFeaturedPosts.slice(0, blocksCountOnScreen / 2)
    const restOfOrdinaryBlocks = blocksCountOnScreen - countOfFeaturedBlocks
    const ordinaryPostsOnScreen = restOfOrdinaryBlocks > 0 ? filteredOrdinaryPosts.slice(0, restOfOrdinaryBlocks) : []

    const postsOnScreen = [...featuredPostsOnScreen, ...ordinaryPostsOnScreen]
    const countOfAllBlocks = countOfFeaturedBlocks + countOfOrdinaryBlocks

    return { postsToShow: postsOnScreen, showLoadMoreBtn: countOfAllBlocks > blocksCountOnScreen }
  }, [blocksCountOnScreen, countOfFeaturedBlocks, countOfOrdinaryBlocks, filteredFeaturedPosts, filteredOrdinaryPosts])

  const loadMorePosts = () => {
    postsBlocksCountState.blocksCountOnScreen += blocksCountInc
  }

  if (!hasPosts) return null

  return (
    <InsightsBaseGrid>
      <AnnouncesGrid $handlesTabletMq>
        {postsToShow.map(({ node }) => (
          // node.featured ? <PostFeatured key={node.id} post={node} /> : <PostOrdinary key={node.id} announce={node} />,
          <ArticleAnnounce key={node.id} announce={node} />
        ))}
      </AnnouncesGrid>
      {showLoadMoreBtn && <LoadMoreButton onClick={loadMorePosts} size="medium" />}
    </InsightsBaseGrid>
  )
}
