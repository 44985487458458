import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Blog } from '~/features/insights'
import { Layout, SEO } from '@/blocks/layout'

export { globals } from '@/lib/global-styles'

const pageTitle = 'Blogs and Articles, Microsoft Cloud and Licensing knowledge library'
const pageDescription =
  'Learn how to navigate through the maze of Microsoft licenses and Cloud services. Our expert authors have compiled their best tips for you.'

const BlogPage = () => {
  return (
    <Layout isOnGrayBg>
      <Blog />
    </Layout>
  )
}

export default BlogPage

export const Head = ({ data }: PageProps<Queries.BlogQueryQuery>) => {
  const seoImageBase = data.seoImage.image.fixed as { images: { fallback: { src: string } } }
  const seoImage = seoImageBase.images.fallback.src

  return <SEO title={pageTitle} description={pageDescription} image={seoImage} />
}

export const pageQuery = graphql`
  query BlogQuery {
    seoImage: contentfulDefaultImage(for: { eq: "SEOlibrary" }) {
      image {
        fixed: gatsbyImageData(
          width: 1200
          height: 630
          placeholder: BLURRED
          layout: FIXED
          formats: [AUTO]
          quality: 20
        )
      }
    }
  }
`
