import { styled } from '@linaria/react'
import {
  desktopSmallMedia,
  desktopLargeMedia,
} from '@/lib/theme'
import { Wrapper } from '@/UI'

const SectionWithHighlight = styled(Wrapper)`
  display: grid;
  grid-template: 'heading' 'body' 'highlight';
  padding-bottom: var(--content-blocks-gap);
  gap: 32px;
  ${desktopSmallMedia} {
    grid-template: 'heading highlight' 'body highlight';
    grid-template-columns: 2fr 1fr;
  }
  ${desktopLargeMedia} {
    justify-self: center;
  }
`

export default SectionWithHighlight
