import React from 'react'
import { styled } from '@linaria/react'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { Button } from '@/UI'
import { pxToRem } from '@/utils/unitsInRem'

const teaserText = 'Get notified when our new videos, articles, and&nbsp;educational materials become available.'

export const Subscribe = () => {
  return (
    <Container>
      <Text dangerouslySetInnerHTML={{ __html: teaserText }} />
      <MailInput placeholder="your@mail.com" />
      <Button>Subscribe</Button>
    </Container>
  )
}

const MailInput = styled.input`
  border-radius: 4px;
  border: 2px solid ${colors.tertiary.tertiary};
  padding: 0 16px;
  color: ${colors.tertiary.tertiary};
  font-size: ${pxToRem(16)};
  font-weight: 400;
  line-height: 1.35;
  height: 48px;
  margin-bottom: 24px;

  ${desktopSmallMedia} {
    margin-bottom: 0;
    flex: 0 1 268px;
    margin-right: 16px;
  }

  &::placeholder {
    color: ${colors.tertiary.tertiary};
    opacity: 0.7;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${desktopSmallMedia} {
    align-items: center;
    flex-direction: row;
    flex: 0 1 auto;
    margin-left: auto;
    max-width: 712px;
  }
`

const Text = styled.span`
  display: flex;
  align-items: center;
  color: ${colors.secondary.secondary};
  font-size: ${pxToRem(14)};
  font-weight: 400;
  line-height: 1.55;
  margin-bottom: 8px;

  ${desktopSmallMedia} {
    margin-bottom: 0;
    flex: 0 1 auto;
    margin-right: 16px;
  }
`
