import { styled } from '@linaria/react'
import { media } from '@/lib/theme'

export const Wrapper = styled.div<{
  $noMobilePadding?: boolean
  $noUltraThinPadding?: boolean
  $noDesktopPadding?: boolean
}>`
  margin: 0 auto;
  width: 100%;
  padding: ${({ $noMobilePadding, $noUltraThinPadding }) =>
    !$noUltraThinPadding && !$noMobilePadding ? '0 var(--layout-indent)' : 'none'};

  ${media.mobileModern} {
    padding: ${({ $noMobilePadding }) => (!$noMobilePadding ? '0 var(--layout-indent)' : 'none')};
  }

  ${media.desktopSmall} {
    max-width: ${({ $noDesktopPadding }) => (!$noDesktopPadding ? '90rem' : 'unset')};
    padding: ${({ $noDesktopPadding }) => (!$noDesktopPadding ? '0 4.5rem' : '0')};
  }
`
